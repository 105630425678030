<style lang="less" scoped>
.list {
  overflow: hidden;
  border-radius: 6px;
  background-color: #fff;
  min-height: 400px;
}
.min {
  font-size: 20px;
  color: #999;
  line-height: 100px;
  text-align: center;
  font-weight: 500;
}
.item {
  cursor: pointer;
  margin: 0 30px;
  height: 118px;
  border-bottom: 2px solid #f2f2f2;
  justify-content: space-between;
  .left {
    .img {
      width: 55px;
      height: 50px;
    }
    .type {
      font-size: 18px;
      font-weight: 500;
      color: #0090f0;
      height: 60px;
    }
    .info {
      margin-left: 20px;
      .title {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }

      .rests {
        margin-top: 20px;
        font-size: 12px;
        color: #999999;
        div {
          margin-right: 35px;
        }
      }
    }
  }
  .right {
    .btn {
      margin-left: 50px;
      text-align: center;
      width: 100px;
      height: 36px;
      line-height: 36px;
      background: #0090f0;
      border-radius: 18px;
      font-weight: bold;
      color: #ffffff;
    }
    .btn1 {
      background-color: #f47070;
    }
    .look {
      font-weight: bold;
      color: #666666;
    }
  }
  &:hover {
    .btn1 {
      background-color: #f13232;
    }
    .look1 {
      color: #0090f0;
    }
  }
}
.img1 {
  width: 14px;
  height: 16px;
  vertical-align: middle;
}
</style>
<template>
  <div>
    <div class="list">
      <div class="min" v-if="listData.list.length == 0">暂无数据</div>
      <div class="flex1 item" v-else v-for="(item, index) in listData.list" :key="index">
        <div class="flex1 left">
          <div class="img" v-if="type == 1 || type == 3">
            <img src="../../../assets/img/tiku/icon_3_9.png" />
          </div>
          <div class="type" v-if="type == 2">【历年真题】</div>
          <div class="info">
            <div class="title">{{ item.examsubject }}</div>
            <div class="flex rests">
              <div>题目数量：{{ item.questionnum }}道</div>
              <div>考试时长：{{ item.examtime }}分钟</div>
              <div>做题人数：{{ item.makenum }}人</div>
            </div>
          </div>
        </div>
        <div class="flex1 right">
          <div class="look look1" v-if="type == 2" @click="analysis(item.id)">查看解析</div>
          <div v-if="type == 3 && item.is_free == 0" class="img1_box">
            <img class="img1" src="../../../assets/img/live/icon_2_.png" />
          </div>
           <div class="btn"  v-if="type == 3&&item.is_free == 0">请购买</div>
          <div class="btn" @click="exam(item.id)" v-if="type != 2&& item.is_free != 0">开始做题</div>
          <!-- <div class="btn btn1" @click="exam(item.exam_id)" v-else>开始做题</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  //1历年真题/模拟试卷  2.错题记录
  props: ["type", "listData"],
  data() {
    return {
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
    };
  },
  mounted() {

  },
  methods: {
    exam(id) {
      if (!this.user_id) {
        this.$router.push({
          path: "/login",
          query: {
            active: 0,
          },
        });
        return;
      }
      this.$router.push({
        path: "/exam",
        query: { c_e_id: id, user_id: this.user_id },
      });
    },
    analysis(id) {
      this.$router.push({
        path: "/analysis",
        query: { id: id, user_id: this.user_id },
      });
    },
  },
};
</script>