<style lang="less" scoped>
.w {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.active {
  color: #0090f0;
  border-bottom: 4px solid #0090f0;
}
.active2 {
  color: #0090f0;
  border-radius: 6px;
  background-color: rgba(0, 144, 240, 0.2);
}
.active3 {
  background-color: #0090f0;
  color: #fff;
}
.dailog_bd::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.dailog_bd::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}
.dailog_bd::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 2px;
}
.dailog_bd::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.dailog_bd::-webkit-scrollbar-corner {
  background: #179a16;
}
.dailog_box {
  z-index: 23;
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 23;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .dailog {
    position: fixed;
    top: 200px;
    left: 50%;
    transform: translate(-50%);
    z-index: 24;
    width: 620px;
    height: 573px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    .dailog_top {
      position: relative;
      width: 100%;
      font-size: 22px;
      height: 72px;
      line-height: 72px;
      text-align: center;
      border-bottom: 2px solid #ececec;
      img {
        position: absolute;
        top: 25px;
        right: 30px;
        width: 19px;
        height: 19px;
        cursor: pointer;
      }
    }
    .dailog_bd {
      height: 500px;
      padding: 30px;
      box-sizing: border-box;
      overflow: auto;

      .dai_tit {
        font-size: 18px;
        color: #0090f0;
        margin-bottom: 20px;
        font-weight: 700;
        img {
          width: 26px;
          height: 23px;
          margin-right: 8px;
          vertical-align: top;
        }
      }
      .dai_list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .dai_item {
          width: 21.9%;
          // width: 120px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          border: 1px solid #dddddd;
          border-radius: 15px;
          margin-right: 20px;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .dai_item:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}

.tiku_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  background-color: #fff;
  margin: 30px 0 20px 0;
  padding: 25px;
  box-sizing: border-box;
  .top_left {
    display: flex;
    .top_tit {
      font-size: 20px;
      font-weight: 600;
    }
    .top_change {
      width: 110px;
      height: 30px;
      background: #0090f0;
      border-radius: 15px;
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 30px;
      margin-left: 25px;
      cursor: pointer;
    }
  }
  .top_right {
    width: 142px;
    height: 30px;
    border: 1px solid rgba(0, 144, 240, 0.2);
    color: #0090f0;
    border-radius: 15px;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    img {
      width: 18px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
.box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    width: 49%;
    height: 216px;
    background-color: #0090f0;
    color: #fff;
    text-align: center;
  }
}
.tiku_tabs {
  width: 100%;
  background-color: #fff;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 6px;

  .tabs_top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 125px;
    border-bottom: 1px solid #f2f2f2;
    .tabs_item {
      width: 158px;
      height: 100%;
      border-right: 1px solid #f2f2f2;
      text-align: center;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
        margin-top: 30px;
      }
    }
  }
  .kemu {
    display: flex;
    // align-items: center;
    width: 100%;
    // height: 67px;
    padding: 15px 25px;
    padding-bottom: 5px;
    box-sizing: border-box;
    span {
      margin-top: 5px;
    }
    .kemu_rt {
      display: flex;
      flex-wrap: wrap;
      width: 95%;
      .kemu_item {
        // margin: 0 10px;
        margin-right: 10px;
        padding: 8px 20px;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
<template>
  <div>
    <el-dialog :visible.sync="daiShow" width="0" :show-close="false">
      <div class="dailog_box">
        <!-- <div class="mask"></div> -->
        <div class="dailog">
          <div class="dailog_top">
            请选择考试类目
            <img
              @click="daiShow = !daiShow"
              src="../../assets/img/tiku/icon_close_01.png"
              alt
            />
          </div>
          <div class="dailog_bd">
            <div v-for="(item, index) in dailogList" :key="index">
              <div class="dai_tit">
                <img :src="item.icon" alt />
                {{ item.title }}
              </div>
              <div class="dai_list">
                <div
                  class="dai_item"
                  v-for="(item2, index2) in item.son"
                  :key="index2"
                  :class="{ active3: active3 == item2.id }"
                  @click="changeDai($event, item2.id, item2)"
                >
                  {{ item2.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <div class="w">
      <div class="tiku_top">
        <div class="top_left">
          <div class="top_tit">{{ title.name }}</div>
          <div class="top_change" @click="daiShow = !daiShow">
            切换考试&gt;&gt;
          </div>
        </div>
        <!-- <div class="top_right">
          <img src="../../assets/img/tiku/icon_3_7.png" alt />
          清除做题记录
        </div>-->
      </div>
      <!-- <div class="box">
        <div>智能组卷</div>
        <div>智能测评</div>
      </div>-->
      <!-- tab切换 -->
      <div class="tiku_tabs">
        <div class="tabs_top">
          <div
            class="tabs_item"
            v-for="(item, index) in tabsList"
            :key="index"
            :class="{ active: active == item.id }"
            @click="tabsChange($event, item.id, index)"
          >
            <!-- <img v-if="active == index" :src="item.img2" alt />
            <img v-else :src="item.img1" alt />-->
            <img :src="item.icon" alt />
            <div>{{ item.title }}</div>
          </div>
        </div>
        <div class="kemu">
          <span>科目：</span>

          <div class="kemu_rt">
            <!-- <div class="kemu_item" :class="{ active2: active2 == 0 }" @click="tabsChange2(0)">全部</div> -->
            <div
              class="kemu_item"
              v-for="(item, index) in kemuList"
              :key="index"
              :class="{ active2: active2 == item.id }"
              @click="tabsChange2(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <!-- 历年真题 / 错题记录 -->
      <History v-if="his" :listData="listData" :type="changeType"></History>
      <!-- <div v-else>
        <router-view />
      </div>-->
      <!-- 章节练习 -->
      <Zhangjie v-if="zhangFlag" :tableData="listData1.list"></Zhangjie>
      <!-- 模拟试卷
      <Moni></Moni>-->

      <!-- 错题记录
      <Wrong></Wrong>
      押题密卷
      <Yati></Yati>-->
    </div>
  </div>
</template>
<script>
import Zhangjie from "@/views/tiku/child/zhangjie.vue";
// import Moni from "@/views/tiku/child/moni.vue";
import History from "@/views/tiku/child/history.vue";
// import Wrong from "@/views/tiku/child/wrong.vue";
// import Yati from "@/views/tiku/child/yati.vue";
import {
  changeCategory,
  navigation,
  threeCategory,
  questionList,
} from "../../assets/js/question";
export default {
  components: {
    Zhangjie,
    // Moni,
    History,
    // Wrong,
    // Yati,
  },
  data() {
    return {
      zhangFlag: false,
      siteId: window.localStorage.getItem("siteId"),
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
      title: {
        name: "",
      },
      his: false,
      daiShow: false,
      active: 0,
      oldActive: 0,
      active2: 0,
      active3: "",
      changeType: "",
      dailogList: [],
      tabsList: [],
      kemuList: [],
      listData: {},
      listData1: [],
    };
  },
  destroyed() {
    window.localStorage.setItem("tikuHeader", this.oldActive);
  },
  mounted() {
    if (!this.user_id) {
      this.$router.push({
        path: "/login",
        query: {
          active: 0,
        },
      });
      return;
    }
    // console.log(this.active)
    if (this.$route.query.active2) {
      this.active3 = this.$route.query.id;
    }
    if (this.active == 1) {
      this.zhangFlag = true;
    } else {
      this.zhangFlag = false;
    }
    if (
      this.active == 2 ||
      this.active == 3 ||
      this.active == 5 ||
      this.active == 4
    ) {
      this.his = true;
      if (this.active == 3 || this.active == 2) {
        this.changeType = 1;
      } else if (this.active == 5) {
        this.changeType = 2;
      } else if (id == 4) {
        this.changeType = 3;
      }
    }
    this.getCategory();
    this.getIndexLog();
  },
  methods: {
    // 获取题库列表
    list() {
      questionList({
        siteId: this.siteId,
        user_id: this.user_id,
        type: this.active,
        action_id: this.active2,
      }).then((res) => {
        // console.log(this.active);
        // console.log(this.active)
        if (this.active == 1) {
          this.zhangFlag = true;
          this.listData1 = res.data;
          // console.log(this.listData1);
        } else {
          this.his = true;
          this.listData = res.data;
        }
      });
    },
    // 获取顶部分类切换
    getCategory() {
      changeCategory({
        action_id: this.active3,
        user_id: this.user_id,
        siteId: window.localStorage.getItem("siteId"),
      }).then((res) => {
        // console.log(res.data.list);
        this.dailogList = res.data.list;
        res.data.list.forEach((item) => {
          if (item.checked == 1) {
            item.son.forEach((item1) => {
              if (item1.checked == 1) {
                this.active3 = item1.id;
                this.title.name = item1.title;
              }
            });
          }
        });

        this.getThreeCategory();
      });
    },
    // 获取练习导航
    getIndexLog() {
      navigation().then((res) => {
        // console.log(res);
        this.tabsList = res.data.nav;
        // console.log(window.localStorage.getItem("tikuHeader"),this.active);
        if (
          window.localStorage.getItem("tikuHeader") == 0 &&
          this.active == 0
        ) {
          // console.log(res.data.nav)
          this.active = res.data.nav[0].id;
          this.oldActive = res.data.nav[0].id;
        } else {
          // console.log(this.$route.query.active2)
          if (this.$route.query.active2) {
            this.active = this.$route.query.active2;
          } else {
            // console.log(window.localStorage.getItem("tikuHeader"));
            this.active = window.localStorage.getItem("tikuHeader");
          }
        }
      });
    },
    // 获取三级分类
    getThreeCategory() {
      threeCategory({
        siteId: window.localStorage.getItem("siteId"),
        action_id: this.active3,
      }).then((res) => {
        // console.log(res);
        this.kemuList = res.data.list;
        this.active2 = res.data.list[0].id;
        this.list();
      });
    },

    tabsChange(e, id, i) {
      this.listData.list = [];
      this.active = id;
      this.his = false;
      this.list();
      window.localStorage.setItem("tikuHeader", id);
      if (id == 1) {
        this.zhangFlag = true;
      } else {
        this.zhangFlag = false;
      }
      if (id == 3 || id == 2 || id == 5 || id == 4) {
        this.his = true;
        if (id == 3 || id == 2) {
          this.changeType = 1;
        } else if (id == 5) {
          this.changeType = 2;
        } else if (id == 4) {
          this.changeType = 3;
        }
      }
    },
    tabsChange2(id) {
      this.active2 = id;
      this.list();
    },
    changeDai(e, id, item) {
      this.active3 = id;
      // console.log(item)
      this.title.name = item.title;
      this.getCategory();
      this.daiShow = false;
    },
  },
};
</script>
